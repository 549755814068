import firebase from "firebase"

import { GATSBY_LOGIN_REDIRECT } from "gatsby-env-variables"

export const handleForgotPassword = async ({
  values,
  callback,
  setMessage,
  errorCallback,
}) => {
  let { email } = values
  let actionCodeSettings = { url: GATSBY_LOGIN_REDIRECT }

  try {
    await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings)
    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback(error)
    setMessage({
      type: "danger",
      content: { code: error.code, message: error.message },
    })
  }
}
