export const parseAuthErrorMessage = (message) => {
  switch (message) {
    case "There is no user record corresponding to this identifier. The user may have been deleted.":
      return `The email you have entered is not yet registered. 
      <div class="content">
        <ul>
          <li>Are you are an existing CarePlus patient? <a href="/recover-email">Recover Your Email</a>.</li>
          <li>Are you a new patient? <a href="/sign-up">Enroll as Patient</a>.</li>
        </ul>
      </div>`
    case "The password is invalid or the user does not have a password.":
      return `The password you have entered is incorrect. <a href="/forgot-password">Forgot Password?</a>`
    default:
      return message
  }
}
