export const authFormFields = (values) => {
  return [
    {
      type: "text",
      name: "email",
      label: "Email",
      isRequired: true,
      value: values.email,
      isFollowUpQuestion: false,
      disabled: values?.hasBeenVerified,
      placeholder: "jdelacruz@gmail.com",
      inclusions: ["sign-in", "verify-email", "sign-up", "forgot-password"],
    },
    {
      type: "password",
      name: "password",
      isRequired: true,
      label: "Password",
      placeholder: "********",
      isFollowUpQuestion: false,
      inclusions: ["sign-in", "sign-up"],
    },
    {
      type: "password",
      name: "confirmPassword",
      isRequired: true,
      label: "Confirm Password",
      placeholder: "********",
      isFollowUpQuestion: false,
      inclusions: ["sign-up"],
    },
    {
      type: "text",
      name: "firstName",
      isRequired: true,
      label: "First Name",
      placeholder: "Juan",
      isFollowUpQuestion: false,
      inclusions: ["recover-email"],
    },
    {
      type: "text",
      name: "lastName",
      isRequired: true,
      label: "Last Name",
      placeholder: "Dela Cruz",
      isFollowUpQuestion: false,
      inclusions: ["recover-email"],
    },
    {
      type: "text",
      name: "lastName",
      isRequired: true,
      label: "Patient Last Name",
      placeholder: "Dela Cruz",
      isFollowUpQuestion: false,
      inclusions: ["verify-name"],
    },
    {
      type: "text",
      name: "lastName",
      isRequired: true,
      label: "Doctor Last Name",
      placeholder: "Dela Cruz",
      isFollowUpQuestion: false,
      inclusions: ["verify-doctor"],
    },
    {
      type: "text",
      name: "mobileNumber",
      isRequired: true,
      label: "Mobile Number",
      placeholder: "09151231234",
      isFollowUpQuestion: false,
      inclusions: ["recover-email"],
    },
    {
      type: "date",
      name: "birthday",
      isRequired: true,
      label: "Birthday",
      isFollowUpQuestion: false,
      inclusions: ["recover-email"],
    },
  ]
}
