import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required."
const YEAR_NOW = new Date(Date.now()).getFullYear()

export const authValidationSchema = (module) => {
  let validationSchema = {}
  if (module !== "verify-name" && module !== "verify-doctor") {
    validationSchema = {
      email: Yup.string()
        .email("Please input a valid email.")
        .required(REQUIRED_MESSAGE),
    }
  }

  if (module === "sign-in")
    validationSchema.password = Yup.string().required(REQUIRED_MESSAGE)
  else if (module === "sign-up") {
    validationSchema.password = Yup.string()
      .required(REQUIRED_MESSAGE)
      .min(7, "Your password must be at least seven characters long.")
      .max(80, "Your password must not exceed 80 characters in length.")
      .matches(
        /(?=.*[a-z])/,
        "Your password must contain at least one lowercase character."
      )
      .matches(
        /(?=.*[A-Z])/,
        "Your password must contain at least one uppercase character."
      )
      .matches(/(?=.*[0-9])/, "Your password must contain at least one number.")
      .matches(
        /(?=.*[!|@|#|$|%|&|_|+|=|.|-])/,
        "Your password must contain at least one of these special characters: !@#$%&_-+=."
      )
      .matches(
        /^[a-zA-Z0-9!@#$%&_+=.-]{7,}$/,
        "Your password contains an invalid special character. Please use any from the following only: !@#$%&_-+=."
      )
    validationSchema.confirmPassword = Yup.string()
      .required(REQUIRED_MESSAGE)
      .oneOf([Yup.ref("password"), null], "Passwords must match")
  } else if (module === "recover-email") {
    delete validationSchema.email
    validationSchema.firstName = Yup.string().required("This field is required")
    validationSchema.lastName = Yup.string().required("This field is required")
    validationSchema.mobileNumber = Yup.string()
      .min(11, "Please input a valid mobile number in this format: 09991234567")
      .max(11, "Please input a valid mobile number in this format: 09991234567")
      .matches(
        /^09[0-9]{9}$/,
        "Please input a valid mobile number in this format: 09991234567"
      )
      .required("This field is required")
    validationSchema.birthday = Yup.object({
      month: Yup.object().shape({
        value: Yup.string().required("This field is required").nullable(),
      }),
      date: Yup.object().shape({
        value: Yup.number().required("This field is required").nullable(),
      }),
      year: Yup.number()
        .min(1900, "Please input a valid year")
        .max(YEAR_NOW, "Please input a valid year")
        .required("This field is required"),
    })
  } else if (module === "verify-name") {
    validationSchema.lastName = Yup.string().required("This field is required")
  } else if (module === "verify-doctor") {
    validationSchema.lastName = Yup.string().required("This field is required")
  }

  return Yup.object().shape(validationSchema)
}
