export const recoverEmailTemplate = ({ data }) => {
  let { firstName, lastName, mobileNumber, birthday } = data
  let birthdayInfo = `${birthday.month.value} ${birthday.date.value} ${birthday.year}`

  return `Personal Details
First Name: ${firstName}
Last Name: ${lastName}
Mobile Number: ${mobileNumber}
Birthday: ${birthdayInfo}`
}
