import { navigate } from "gatsby"

import { handleEmailLogin, handleVerifyName } from "./signin"
import { handleRequestSend } from "./recoverEmail"
import { checkIfEmailAlreadyExists } from "./signup"
import { handleForgotPassword } from "./forgotPassword"
import { AppReducer } from "../../../context/AppReducer"

export const handleAuthSubmit = async (payload, dispatch) => {
  let { setLoading, setMessage } = payload

  switch (payload.module) {
    case "sign-in":
      handleEmailLogin({
        ...payload,
        callBack: () => {
          setLoading(false)
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break
    case "verify-name":
    case "verify-doctor":
      handleVerifyName({
        ...payload,
        callBack: () => {
          setLoading(false)
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break
    case "forgot-password":
      handleForgotPassword({
        ...payload,
        callback: () => {
          setLoading(false)
          setMessage({
            content:
              "Please check your email for instructions to reset your password.",
          })
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break
    case "verify-email":
      let { email } = payload.values
      const IS_ALREADY_ENROLLED = await checkIfEmailAlreadyExists(email)

      dispatch({
        type: "SAVE_AUTH",
        payload: { email, hasBeenVerified: true },
      })

      if (IS_ALREADY_ENROLLED) {
        navigate("/sign-in")
      } else {
        navigate("/sign-up")
      }

      setLoading(false)
      break
    case "sign-up":
      let { values } = payload

      dispatch({
        type: "SAVE_AUTH",
        payload: { ...values },
      })

      navigate("/enroll-patient")
      break
    case "recover-email":
      handleRequestSend({
        type: "recover_email",
        data: payload.values,
        callback: () => {
          setLoading(false)
          payload.resetForm()

          setMessage({
            content:
              "Your request has been sent. A CarePlus coordinator will review your provided information and will send your registered email via SMS within one working day",
          })
        },
        errorCallback: () => {
          setLoading(false)
        },
      })
      break
    default:
      break
  }
}
