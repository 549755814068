import { GATSBY_ENV } from "gatsby-env-variables"
import { sendToZendesk } from "../../../services/zendeskService"
import { recoverEmailTemplate } from "../templates/recoverEmailTemplate"

export const sendRequestToZendesk = (config) => {
  let { data, documents, ticketTemplate, type } = config // Removed file array
  let isTest = GATSBY_ENV !== "production"

  let tags = [type]
  if (isTest) tags.push("test")

  let subject = `${isTest ? "[TEST] " : ""} Email recovery request of ${
    data?.firstName
  } ${data?.lastName}`

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: `${data?.firstName} ${data?.lastName}`,
      email: data?.emailAddress,
    },
    comment: { body: ticketTemplate({ data }) },
  }

  return sendToZendesk(requestBody || {}, documents || [])
}

export const handleRequestSend = async ({
  type,
  data,
  callback,
  documents,
  errorCallback,
}) => {
  try {
    await sendRequestToZendesk({
      data,
      documents,
      ticketTemplate: recoverEmailTemplate,
      type: type,
    })

    if (callback) callback()
  } catch (error) {
    console.error(error)
    if (errorCallback) errorCallback()
  }
}
